<template>
    <div class="card st-framework-card">

        <div class="card-header">
            <table style="border-collapse: collapse; width: 100%;">
                <tr>
                    <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                        <font-awesome-icon :icon="icon" size="sm"/>
                    </td>
                    <td style="text-align: center;">
                        {{ $i18n.tc('translations.' + name, 2) }}
                    </td>
                    <td style="width: 50px;"/>
                </tr>
            </table>
        </div>

        <div class="card-body" v-if="pageLoaded">
            <div class="card-columns">

                <div class="card">
                    <div class="card-header" style="text-align: center;">
                        {{ $i18n.tc('translations.Estimate', 2) }} / {{ $i18n.tc('translations.Quote', 2) }} / {{ $i18n.tc('translations.Order', 2) }}
                    </div>
                    <div class="card-body st-fadein-content">
                        <RecordForm :fields="fields.documentFields"
                                    :formErrors="formErrors" :generalError="generalError"
                                    :languages="languages" :userLanguage="userLanguage"
                                    v-on:model-data="onDocumentData"/>
                        <div class="container-fluid mt-3 p-0">
                            <div class="row">
                                <div class="col">
                                </div>
                                <div class="col-auto">
                                    <template v-for="(action,index) in actions.documentActions">
                                        <DropDownAction :key="action.name + '-' + index" :action="action"
                                                        v-on:download-event="download($event, documentData)"
                                                        v-if="action.type === 'DROPDOWN' && action.placement === 'TABLEFOOTERRIGHT'"/>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" style="text-align: center;">
                        {{ $i18n.tc('translations.Project', 2) }}
                    </div>
                    <div class="card-body st-fadein-content">
                        <RecordForm :fields="fields.projectFields"
                                    :formErrors="formErrors" :generalError="generalError"
                                    :languages="languages" :userLanguage="userLanguage"
                                    v-on:model-data="onProjectData"/>
                        <div class="container-fluid mt-3 p-0">
                            <div class="row">
                                <div class="col">
                                </div>
                                <div class="col-auto">
                                    <template v-for="(action,index) in actions.projectActions">
                                        <DropDownAction :key="action.name + '-' + index" :action="action"
                                                        v-on:download-event="download($event, projectData)"
                                                        v-if="action.type === 'DROPDOWN' && action.placement === 'TABLEFOOTERRIGHT'"/>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" style="text-align: center;">
                        {{ $i18n.t('translations.Inventory Usage') }}
                    </div>
                    <div class="card-body st-fadein-content">
                        <RecordForm :fields="fields.inventoryFields"
                                    :formErrors="formErrors" :generalError="generalError"
                                    :languages="languages" :userLanguage="userLanguage"
                                    v-on:model-data="onInventoryData"/>
                        <div class="container-fluid mt-3 p-0">
                            <div class="row">
                                <div class="col">
                                </div>
                                <div class="col-auto">
                                    <template v-for="(action,index) in actions.inventoryActions">
                                        <DropDownAction :key="action.name + '-' + index" :action="action"
                                                        v-on:download-event="download($event, inventoryData)"
                                                        v-if="action.type === 'DROPDOWN' && action.placement === 'TABLEFOOTERRIGHT'"/>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import DropDownAction from "@/components/actions/DropDownAction";
import FileDownload from "@/mixins/FileDownload";
import RecordForm from "@/components/forms/RecordForm";

export default {
    name: 'Reporting',
    components: {
        DropDownAction,
        RecordForm,
    },
    props: ['name', 'icon', 'api', 'state', 'userLanguage', 'languages'],
    mixins: [FileDownload],
    data() {
        return {
            pageLoaded: false,
            actions: null,
            fields: null,
            documentData: null,
            inventoryData: null,
            projectData: null,
            formErrors: null,
            generalError: null,
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        download(choice, data) {
            this.state.loading = true;
            this.$http.post(choice.api, data, {'responseType': 'blob'}).then((res) => {
                this.fileDownload(choice, res.data);
                this.state.loading = false;
            }).catch((error) => {
                this.handleFormErrors(error);
            });
        },
        fetchData() {
            this.$http.get(this.api + '/manage', {}).then((res) => {
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.state.loading = false;
                this.pageLoaded = true;
            }).catch((error) => {
                console.log("Reporting:fetchData():error:", error);
            });
        },
        handleFormErrors(error) {
            if (error.response?.status === 422) {
                this.formErrors = error.response.data.errors;
            } else {
                this.generalError = error.response?.data?.message;
                console.log("Reporting:handleFormErrors():error:", error);
            }
        },
        onDocumentData(data) {
            this.documentData = data;
        },
        onInventoryData(data) {
            this.inventoryData = data;
        },
        onProjectData(data) {
            this.projectData = data;
        },
    }
}
</script>

<style scoped>
</style>